import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Purchase.module.sass';
import { ethers, utils } from 'ethers';
import TextInput1 from '../../components/TextInput1';
/**** Aaron Added    ****/
//    start
import moment from 'moment';
// import crypto from 'crypto-js';
import {
  createBuyerUser,
  updateAsset,
  createTxData,
} from '../../services/ApiServices';
//    end
import { useHistory, useParams, Link } from 'react-router-dom';
import { getProvider, GetMaticPrice } from '../../services/ApiServices';
import Modal from '../../components/Modal';
import { Row, Col, Spin } from 'antd';
import TextArea from '../TextArea';
import { useSnackbar } from 'notistack';
import { useWeb3React } from '@web3-react/core';
import Token721 from '../abi/NftToken721A.json';
import Token1155 from '../abi/NftToken1155.json';
import { getDatabase, ref, onValue, set, push } from 'firebase/database';
import { Database } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import {
  injected,
  // useInactiveListener,
} from '../../utils/connections';
import Loader from '../Loader';
import { isMobile } from 'react-device-detect';

const Purchase = (props, { className }) => {
  const { account, active, activate, error } = useWeb3React();
  const [address, setAddress] = useState();

  const [email, setEmail] = useState('');

  const [userName, setUserName] = useState('');

  const [btn_txt, setBtn_txt] = useState('Mint Now');

  const [userNameError, setUserNameError] = useState('');

  const [isCheckOut, setIsCheckOut] = useState(false);

  const [isCreate, setIsCreate] = useState(false);

  const [errormsg, setErrormsg] = useState();

  const [delevered, setDeleverd] = useState(false);

  const [eth, setEth] = useState(1);

  const [txs, setTxs] = useState();

  const [txHash, setTxHash] = useState('');

  const [value, setValue] = useState('');

  const [isDelevered, setIsDeleverd] = useState(false);

  const [isSelectWallet, setIsSelectWallet] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [emailId, setEmailId] = useState('');
  /*****       Aaron Added ****/
  //   start
  const [firstName, setfirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [panNumber, setPanNumber] = useState('');
  //   end
  const [uid, setUid] = useState('');
  const [premiumnft, setPremiumnft] = useState('');
  const [kycVerification, setKycVerification] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isNotWhitelisted, setIsNotWhitelisted] = useState(false);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [balance, setBalance] = useState(0);
  const [sold, setSold] = useState(0);
  const [supply, setSupply] = useState(0);
  let history = useHistory();
  const [PremiumNFT, setPremiumNFt] = useState([]);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const { id } = useParams();
  var crypto = require('crypto-js');

  const transactionLink = 'https://mumbai.polygonscan.com/tx/';

  useEffect(() => {
    // console.log("checking props...", props, eth);
  }, [props, eth]);

  const getUSD = async () => {
    let result;
    try {
      result = await GetMaticPrice();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result) {
        setValue(result.USD);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const db = getDatabase();
  const auth = getAuth();
  const getData = (uid) => {
    const userId = auth?.currentUser?.uid;
    setUid(userId);
    return onValue(
      ref(db, process.env.REACT_APP_FIREBASE_URL + userId),
      (snapshot) => {
        // console.log(snapshot, "user")
        const username =
          (snapshot.val() && snapshot.val().email) ||
          (snapshot.val() && snapshot.val().firstName);
        setEmailId(username);
        const kyc =
          (snapshot.val() && snapshot.val().kycStatus) ||
          'Pending Verification';
        setKycVerification(kyc);
        /*****       Aaron Added ****/
        //   start
        const firstName = snapshot.val() && snapshot.val().firstName;
        setfirstName(firstName);
        const lastName = snapshot.val() && snapshot.val().lastName;
        setLastName(lastName);

        if (kyc === 'Verified') {
          const encryptedPanNo =
            snapshot.val() && snapshot.val().PAN?.panNumber;
          const decryptedPanNo = crypto.AES.decrypt(
            encryptedPanNo,
            '@wowTalkies123'
          ).toString(crypto.enc.Utf8);
          setPanNumber(decryptedPanNo);
        }
        //   end
      },
      {
        onlyOnce: true,
      }
    );
  };
  useEffect(() => {
    getUSD();
    if (auth?.currentUser?.uid) {
      getData();
    }
  }, [auth?.currentUser?.uid, emailId]);

  const wallet = async () => {
    let meta;
    try {
      if (!window.ethereum) {
        setIsSelectWallet(false);
      }
      activate(injected);
      setIsSelectWallet(false);
      if (account) {
        buyNow();
        purchase();
        setAddress(account);
        setIsSelectWallet(false);
      } else setErrormsg(error);
    } catch (err) {
      setIsSelectWallet(false);
      return setErrormsg(err.message);
    }
    setErrormsg('');
  };

  const handleEth = (value) => {
    if (props.item.assetType === 'ERC-1155') {
      if (value > props.item.totalSupply)
        return setErrormsg('Quantity reached limit');
      else {
        setErrormsg('');
        setEth(value);
      }
    }
  };
  const UpdateAsset = async (id) => {
    let result;
    let body = {
      ownerAddress: account,
      // total: eth
    };
    try {
      result = await updateAsset(id, body);
    } catch (e) {
      console.log(e);
    }
    try {
      if (result) {
        console.log(result);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const buyNow = async () => {
    setIsLoading(true);
    setTxHash('');
    setIsSuccess(false);
    setErrormsg('');
    setBtn_txt('Minting');
    try {
      if (!window.ethereum) {
        setIsNotWhitelisted(true);
        setErrormsg('Metamask is not found. Please install it.');
        throw new Error('Metamask is not found.');
      }
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let value = 0;
        let network = await provider.getNetwork();
        if (network.chainId !== 80001) {
          setIsLoading(false);
          return setErrormsg('Please connect to Polygon Mainnet.');
        }
        if (eth < 1) {
          setErrormsg('Please enter minimum quantity ( Qty 1 )');
          setIsLoading(false);
          return;
        }
        let signer = provider.getSigner();

        ethers.utils.getAddress(account);
        value = await provider.getBalance(account, 'latest');
        let displayBalance = utils.formatUnits(
          utils.parseUnits(value.toString(), 'wei'),
          'ether'
        );
        let token_Price, currentPrice;
        let whitelistMarket = new ethers.Contract(
          props?.item?.contractAddress,
          Token721.abi,
          signer
        );

        try {
          // mint_token = await whitelistMarket.mintToken(quantity);

          token_Price = await whitelistMarket.tokenPrice();

          // currentPrice = token_Price.toString().split(",")[0];

          currentPrice = utils.formatUnits(
            utils.parseUnits(token_Price.toString().split(',')[0], 'wei'),
            'ether'
          );
          if (parseFloat(displayBalance) < parseFloat(currentPrice) * eth) {
            setIsLoading(false);
            setBtn_txt('Mint Now');
            return enqueueSnackbar('Insufficient Balance to complete minting', {
              variant: 'error',
            });
          } else {
            let estimatedGasLimit;
            let estimatedGasPrice;
            let quantity = eth;
            let totalGwei = token_Price.mul(quantity);
            try {
              estimatedGasLimit = await whitelistMarket.estimateGas.buyToken(
                quantity,
                { value: totalGwei }
              );
              console.log(estimatedGasLimit);
            } catch (e) {
              setBtn_txt('Mint Now');
              console.log('Error estimatedGasLimit: ', e);
              setIsLoading(false);
              // let message = e.message.split(":");
              return setErrormsg('Error in Estimated Gas Limit');
            }
            try {
              estimatedGasPrice = await provider.getGasPrice();
            } catch (error) {
              let message = error.message.split(':');
              setIsLoading(false);
              setBtn_txt('Mint Now');
              return setErrormsg(
                message[0] === 'MetaMask Tx Signature' ? message[1] : message
              );
            }
            let overrides = {
              gasLimit: estimatedGasLimit,
              gasPrice: estimatedGasPrice,
              value: totalGwei,
            };

            let buyTx;
            try {
              buyTx = await whitelistMarket.buyToken(quantity, overrides);
              setIsLoading(true);
              setIsSuccess(false);
              setTxHash(buyTx.hash);
            } catch (error) {
              if (error.message) {
                let message = error.message.split(':');
                setIsLoading(false);
                setIsSuccess(false);
                setBtn_txt('Mint Now');
                setErrormsg(
                  message[0] === 'MetaMask Tx Signature' ? message[1] : message
                );
                return enqueueSnackbar('Transaction failed', {
                  variant: 'error',
                });
              }
            }
            let buyReceipt;
            try {
              buyReceipt = await buyTx.wait();
            } catch (error) {
              setIsLoading(false);
              if (error.message) {
                let message = error.message.split(':');
                setIsLoading(false);
                setBtn_txt('Mint Now');
                return setErrormsg(
                  message[0] === 'MetaMask Tx Signature' ? message[1] : message
                );
              }
              console.log('Erron on wait ...', error);
              setIsLoading(false);
              setErrormsg('Transaction failed');
              setIsSuccess(false);
              setBtn_txt('Mint Now');
              return enqueueSnackbar('Transaction failed', {
                variant: 'error',
              });
            }
            // let ethUsed = utils.formatUnits(
            //   utils.parseUnits(buyReceipt.gasUsed.toString(), "gwei"),
            //   "ether"
            // );

            /******    Aaron added ****/
            //    start
            let body = {
              uid: uid,
              email: emailId,
              firstName: firstName,
              lastName: lastName,
              panNumber: panNumber,
              walletAddress: account,
              transactionHash: buyReceipt.transactionHash,
              contractAddress: props?.item?.contractAddress,
              contractType: props?.item?.tokenStandard,
              collectionName: props?.item?.name,
              //  nftName: props?.item.collectionId?.displayName,
              totalPrice: parseInt(totalGwei) / 1e18,
              date: moment(Date.now()).format('DD-MM-YYYY'),
            };
            //   end

            if (buyReceipt.status) {
              setBtn_txt('Mint Now');
              setIsSuccess(true);
              setIsLoading(false);
              setErrormsg('');
              write721UserData(uid);
              /*** Aaron Added  ****/
              //  start
              createTxData(body);
              //  end
            }
            setTxs([`https://etherscan.io/tx/${buyReceipt.transactionHash}`]);
            return enqueueSnackbar('Transaction completed', {
              variant: 'success',
            });
            // props.onClose();
          }
        } catch (error) {
          setIsLoading(false);
          console.log(error);
          let message = error.message.split(':');
          return setErrormsg(
            message[0] === 'MetaMask Tx Signature' ? message[1] : message
          );
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setErrormsg(error.message);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      return setErrormsg(err.message);
    }
  };
  const purchase = async () => {
    setIsLoading(true);
    setTxHash('');
    setIsSuccess(false);
    setErrormsg('');
    setBtn_txt('Minting');
    try {
      if (!window.ethereum) {
        setIsNotWhitelisted(true);
        setErrormsg('Metamask is not found. Please install it.');
        throw new Error('Metamask is not found.');
      }
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let value = 0;
        let network = await provider.getNetwork();
        if (network.chainId !== 80001) {
          setIsLoading(false);
          return setErrormsg('Please connect to Polygon Mainnet.');
        }
        if (eth < 1) {
          setErrormsg('Please enter minimum quantity ( Qty 1 )');
          setIsLoading(false);
          return;
        }
        let signer = provider.getSigner();

        ethers.utils.getAddress(account);
        value = await provider.getBalance(account, 'latest');
        let displayBalance = utils.formatUnits(
          utils.parseUnits(value.toString(), 'wei'),
          'ether'
        );
        let token_Price, currentPrice;
        let whitelistMarket = new ethers.Contract(
          props?.item?.collectionId?.contractAddress,
          Token1155.abi,
          signer
        );

        try {
          token_Price = await whitelistMarket.saleItems(props.item.tokenId);
          console.log(token_Price);
          currentPrice = utils.formatUnits(
            utils.parseUnits(token_Price.toString().split(',')[0], 'wei'),
            'ether'
          );
          if (parseFloat(displayBalance) < parseFloat(currentPrice) * eth) {
            setIsLoading(false);
            setBtn_txt('Mint Now');
            return enqueueSnackbar('Insufficient Balance to complete minting', {
              variant: 'error',
            });
          } else {
            let estimatedGasLimit;
            let estimatedGasPrice;
            let tokenId = props.item.tokenId;
            let amount = eth;
            let totalGwei = token_Price[0].mul(amount);
            try {
              estimatedGasLimit = await whitelistMarket.estimateGas.buyToken(
                tokenId,
                amount,
                { value: totalGwei }
              );
              console.log(estimatedGasLimit);
            } catch (e) {
              setBtn_txt('Mint Now');
              console.log('Error estimatedGasLimit: ', e);
              setIsLoading(false);
              // let message = e.message.split(":");
              return setErrormsg('Error in Estimated Gas Limit');
            }
            try {
              estimatedGasPrice = await provider.getGasPrice();
            } catch (error) {
              let message = error.message.split(':');
              setIsLoading(false);
              setBtn_txt('Mint Now');
              return setErrormsg(
                message[0] === 'MetaMask Tx Signature' ? message[1] : message
              );
            }
            let overrides = {
              gasLimit: estimatedGasLimit,
              gasPrice: estimatedGasPrice,
              value: totalGwei,
            };

            let buyTx;

            try {
              buyTx = await whitelistMarket.buyToken(
                tokenId,
                amount,
                overrides
              );
              setIsLoading(true);
              setIsSuccess(false);
              setTxHash(buyTx.hash);
            } catch (error) {
              if (error.message) {
                let message = error.message.split(':');
                setIsLoading(false);
                setIsSuccess(false);
                setBtn_txt('Mint Now');
                setErrormsg(
                  message[0] === 'MetaMask Tx Signature' ? message[1] : message
                );
                return enqueueSnackbar('Transaction failed', {
                  variant: 'error',
                });
              }
            }
            let buyReceipt;
            try {
              buyReceipt = await buyTx.wait();
            } catch (error) {
              setIsLoading(false);
              if (error.message) {
                let message = error.message.split(':');
                setIsLoading(false);
                setBtn_txt('Mint Now');
                return setErrormsg(
                  message[0] === 'MetaMask Tx Signature' ? message[1] : message
                );
              }
              console.log('Erron on wait ...', error);
              setIsLoading(false);
              setErrormsg('Transaction failed');
              setIsSuccess(false);
              setBtn_txt('Mint Now');
              return enqueueSnackbar('Transaction failed', {
                variant: 'error',
              });
            }
            /*** Aaron Added   *****/
            let body = {
              uid: uid,
              email: emailId,
              firstName: firstName,
              lastName: lastName,
              walletAddress: account,
              panNumber: panNumber,
              transactionHash: buyReceipt.transactionHash,
              contractAddress: props?.item?.collectionId?.contractAddress,
              contractType: props.item.tokenStandard,
              collectionName: props?.item.collectionId?.title,
              nftName: props.item.displayName,
              tokenId: tokenId,
              amount: amount,
              totalPrice: parseInt(totalGwei) / 1e18,
              date: moment(Date.now()).format('DD-MM-YYYY'),
            };
            //   end

            if (buyReceipt.status) {
              setBtn_txt('Mint Now');
              setIsSuccess(true);
              setIsLoading(false);
              setErrormsg('');
              getBalance();
              UpdateAsset(props.item._id);
              writeUserData(uid);
              /*****    Aaron Added   ****/
              //   start
              createTxData(body);
              //   end
            }
            setTxs([`https://etherscan.io/tx/${buyReceipt.transactionHash}`]);
            return enqueueSnackbar('Transaction completed', {
              variant: 'success',
            });
            // props.onClose();
          }
        } catch (error) {
          setIsLoading(false);
          console.log(error);
          let message = error.message.split(':');
          return setErrormsg(
            message[0] === 'MetaMask Tx Signature' ? message[1] : message
          );
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setErrormsg(error.message);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      return setErrormsg(err.message);
    }
  };

  const getBalance = async () => {
    setBalanceLoading(true);
    if (!account) {
      setBalanceLoading(false);
    } else {
      setErrormsg('');
      let token_Price, currentTokenPrice;

      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let signer = provider.getSigner();
      let network = await provider.getNetwork();
      if (network.chainId !== 80001) {
        return setErrormsg('Please connect to Polygon network.');
      }
      let whitelistMarket = new ethers.Contract(
        props?.item?.collectionId?.contractAddress,
        Token1155.abi,
        signer
      );
      try {
        token_Price = await whitelistMarket.saleItems(props.item.tokenId);
        currentTokenPrice = utils.formatUnits(
          utils.parseUnits(token_Price.toString().split(',')[0], 'wei'),
          'ether'
        );
        setTokenPrice(currentTokenPrice);
        setSupply(token_Price.toString().split(',')[1]);
        setSold(token_Price.toString().split(',')[2]);
        setBalanceLoading(false);
      } catch (error) {
        console.log(error);
        setBalanceLoading(false);
      }
    }
  };

  const getEligiblity = async () => {
    setErrormsg('');
    let token_Price, currentTokenPrice;

    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner();
    let network = await provider.getNetwork();
    if (network.chainId !== 80001) {
      // setIsLoading(false);
      return setErrormsg('Please connect to Polygon Mainnet.');
    }
    let whitelistMarket = new ethers.Contract(
      props?.item?.contractAddress,
      Token721.abi,
      signer
    );
    try {
      token_Price = await whitelistMarket.tokenPrice();
      // currentTokenPrice = token_Price.toString().split(",")[0];
      currentTokenPrice = utils.formatUnits(
        utils.parseUnits(token_Price.toString().split(',')[0], 'wei'),
        'ether'
      );
      setTokenPrice(currentTokenPrice);
      // let balance = token_Price.toString().split(",")[1];
      // setBalance(balance);
    } catch (error) {
      console.log(error);
    }
  };

  function writeUserData(uid) {
    const db = getDatabase();
    const premiumnft = ref(
      db,
      process.env.REACT_APP_FIREBASE_URL + uid + '/PremiumNFT/'
    );
    const PremiumNFT = push(premiumnft);
    set(PremiumNFT, [
      props?.item.collectionId?.title
        ? props?.item.collectionId?.title
        : props?.item.collectionId?.name,
    ]);
  }
  function write721UserData(uid) {
    const db = getDatabase();
    const premiumnft = ref(
      db,
      process.env.REACT_APP_FIREBASE_URL + uid + '/PremiumNFT/'
    );
    const PremiumNFT = push(premiumnft);
    set(PremiumNFT, [props?.item?.name]);
  }

  useEffect(() => {
    if (account && props.item.tokenStandard === 'ERC-721') {
      getEligiblity();
    } else {
      getBalance();
    }
  }, [account, tokenPrice, sold, supply]);

  // useEffect(() => {
  //   if(props.item.collectionId.tokenStandard === 'ERC-1155' && supply === 0){
  //     setBalanceLoading(true)
  //   }
  // },[balanceLoading])

  const handleUserName = (value) => {
    if (value.length < 3) {
      setUserNameError('Please enter username with atleast 3 characters');
      setUserName(value);
    } else {
      setUserNameError('');
      setUserName(value);
    }
  };

  const handleEmail = (value) => {
    // if(value.length < 3){
    //   setEmailError("Please enter Email with atleast 3 characters")
    //   setEmail(value)
    // }

    // else{
    //   setEmailError("")
    setEmail(value);
    // }
  };

  const register = async () => {
    let result, body;

    body = {
      address: address,
      userName: userName,
      email: email,
    };

    try {
      result = await createBuyerUser(body);
    } catch (error) {
      setErrormsg(error.message);
    }

    if (result.success) {
      setIsCreate(false);
      let selectedProvider = await getProvider();
      console.log('handleEmail provider', selectedProvider);
      const provider = new ethers.providers.Web3Provider(selectedProvider);
      const signer = provider.getSigner();
      setAddress(address);
      try {
        ethers.utils.getAddress(address);
      } catch (error) {
        setErrormsg(error.message);
      }

      try {
        await provider.getBalance(address).then((balance) => {
          value = parseFloat(ethers.utils.formatEther(balance));
        });

        if (value < props.item.tokenPrice * eth) {
          setErrormsg('Insufficient Balance to complete the purchase');
        } else {
          let tx = await signer.sendTransaction({
            to: address,
            value: ethers.utils.parseEther(
              (props.item.tokenPrice * eth).toString()
            ),
          });
          // console.log({ eth, address });
          // console.log("tx", tx);
          setTxs([tx]);
          props.onClose();
          setIsDeleverd(true);
        }
      } catch (error) {
        setErrormsg(error.message);
      }
    } else {
      setErrormsg('Something went wrong please try again later.');
    }
  };
  return (
    <>
      {/* {isLoading ? <Loader className={styles.loader} />
        :  */}
      {isCreate ? (
        <div>
          <h3 className="text-center">Tell Us About Yourself</h3>
          <div className={styles.register}>
            <TextInput1
              className={styles.field}
              label="User Name"
              name="userName"
              type="text"
              onChange={(e) => handleUserName(e.target.value)}
              placeholder="Enter name"
            />
            <TextInput1
              className={styles.field}
              label="Email"
              name="email"
              type="email"
              onChange={(e) => handleEmail(e.target.value)}
              placeholder="Enter email"
            />
          </div>

          <div className={styles.btns}>
            <button
              className={cn('button', styles.button)}
              onClick={() => register()}
            >
              Save
            </button>
            <br />
            <button
              className={cn('button-stroke', styles.button)}
              onClick={() => {
                setIsCreate(false);
                props.onClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : isCheckOut ? (
        <div className={cn(className, styles.connect)}>
          <h4>Item</h4>
          <div className={styles.item}>
            {/* <img
                src={
                  props.item.assetType === 'ERC-721' ? props.item.attachments.length > 0 &&
                  props.item.attachments[0].fileType === "image"
                  ? props.item.attachments[0].url
                  : props.item.title[0] :
                  props.asset.postId.attachments.length > 0 &&
                    props.asset.postId.attachments[0].fileType === "image"
                    ? props.asset.postId.attachments[0].url
                    : props.asset.postId.title[0]
                }
                className={styles.image}
              /> */}
            <>
              <img
                className={styles.image}
                src={props.item?.previewImage}
                alt="Card"
              />
              {/* {assets.attachments[0].fileType == "IMAGE" ? (
                      <img
                        src={assets.attachments[0].url}
                        alt="Card"
                        className={styles.image}
                      />
                    ) : (
                      <video
                        autoPlay
                        muted
                        loop
                        src={assets.attachments[0].url}
                        type="video/mp4"
                        className={styles.image}
                      />
                    )} */}
            </>
            <div>
              {props.item.collectioName}
              {/* <Link to={"/profile/" + props.asset.userId.id}>
                  <div className={styles.avatar}>
                    <img src={props.asset.userId.imageUrl} alt="Avatar" />
                  </div>
                </Link> */}
              {/* <div className={styles.description}>
                  <div className={styles.category}>Creator</div>
                  <div className={styles.text}>{props.asset.userId.userName}</div>
                </div> */}
            </div>
          </div>

          <div className={styles.info}>
            <div>
              <label>Price</label>
              <div>{props.item.tokenPrice} ETH</div>
            </div>

            <div>
              <TextInput1
                className={styles.field}
                label="Quantity"
                name="Item"
                type="text"
                placeholder="Enter Quantity"
              />
            </div>

            <div className={styles.info}>
              <div>You will pay</div>
              <div>1,500 ETH</div>
              <label>1,500.00</label>
            </div>
          </div>
          <div className={styles.btns}>
            <button
              className={cn('button', styles.button)}
              onClick={() => {
                if (address) wallet(false);
                else setIsSelectWallet(true);
              }}
            >
              Mint
            </button>
            <button
              className={cn('button-stroke', styles.button)}
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className={cn(className, styles.connect)}>
          <h4 className={styles.collectioName}>
            {props?.item?.title ? props?.item?.title : props?.item?.displayName}
          </h4>
          <div className={styles.item}>
            {/* <img
                src={
                  props.item.assetType === 'ERC-721' ? props.item.attachments.length > 0 &&
                  props.item.attachments[0].fileType === "image"
                  ? props.item.attachments[0].url
                  : props.item.title[0] :
                  props.asset.postId.attachments.length > 0 &&
                    props.asset.postId.attachments[0].fileType === "image"
                    ? props.asset.postId.attachments[0].url
                    : props.asset.postId.title[0]
                }
                className={styles.image}
              /> */}

            {props.item.imageUrl !== '' ? (
              <>
                <img
                  alt=""
                  className={styles.image}
                  src={
                    props?.item?.imageUrl
                      ? props?.item?.imageUrl
                      : props?.item?.featuredImage
                  }
                />
              </>
            ) : (
              <video
                autoPlay
                muted
                loop
                src={
                  props.item?.videoUrl
                    ? props.item?.videoUrl
                    : props.item?.attachments[0]?.fileType === 'VIDEO' &&
                      props.item?.attachments[0]?.url
                }
                type="video/mp4"
                className={styles.image}
              />
            )}
            {/* <img
              className={styles.image}
              src={props.item?.previewImage ? props.item?.previewImage : props.item?.imageUrl}
              alt="Card"
            /> */}
            <div className={styles.text}>
              <div className={styles.info}>
                <div className={styles.priceInfo}>
                  <label>Price </label>
                  <div>
                    {props?.item?.tokenPrice || tokenPrice} MATIC
                    {/* {props.item?.chainName === "Polygon" ? "MATIC" : "ETH"} */}
                  </div>
                  <div className={styles.note}>
                    {(value * (props?.item?.tokenPrice || tokenPrice)).toFixed(
                      3
                    )}{' '}
                    USD
                  </div>
                </div>

                <div style={{ marginTop: '8px' }}>
                  <TextInput1
                    className={styles.field}
                    label="Quantity"
                    name="Item"
                    type="number"
                    // value={eth ? eth : 1}
                    value={eth}
                    onChange={(e) => setEth(e.target.value)}
                    placeholder="Enter Quantity"
                    min="1"
                  />
                </div>

                <div className={styles.Price}>
                  <label>You will pay </label>
                  <span>
                    {((props?.item?.tokenPrice || tokenPrice) * eth).toFixed(3)}{' '}
                    MATIC
                    {/* {props.item?.chainName === "Polygon" ? "MATIC" : "ETH"} */}
                  </span>
                  <span className={styles.note}>
                    {(
                      value *
                      (props?.item?.tokenPrice || tokenPrice) *
                      eth
                    ).toFixed(3)}{' '}
                    USD
                  </span>
                </div>

                {sold && supply ? (
                  <div className={styles.Price}>
                    <label>Minted :</label>
                    <span>
                      {sold} / {supply}
                    </span>
                  </div>
                ) : null}
              </div>
              {/* <Link to={"/profile/" + props.item.userId.id}>
                  <div className={styles.avatar}>
                    <img src={props.item.userId.imageUrl} alt="Avatar" />
                  </div>
                </Link> */}
              {/* <div className={styles.description}>
                  <div className={styles.category}>Creator</div>
                  <div className={styles.text}>{props.item.userId.userName}</div>
                </div> */}
            </div>
          </div>
          {/* {sold === 0 && supply === 0 && account && props?.item?.tokenStandard === "ERC-1155" ?  <Loader className={styles.loader}/>:  <> */}
          {balanceLoading ? (
            <Loader className={styles.loader} />
          ) : (
            <>
              {account &&
              props?.item?.collectionId?.tokenStandard === 'ERC-1155' &&
              sold === supply ? (
                <p className={styles.soldOut}>All items sold out</p>
              ) : (
                <button
                  className={cn('button', styles.button)}
                  style={{ display: 'flex' }}
                  onClick={() => {
                    if (!active) setIsSelectWallet(true);
                    else if (!emailId) {
                      history.push('/');
                      // props.onClose()
                      return enqueueSnackbar(
                        'Please register / sign in before buying',
                        { variant: 'error' }
                      );
                    } else if (kycVerification == 'Pending Verification') {
                      history.push('/profile/' + account);
                      return enqueueSnackbar(
                        'Please complete your KYC process for minting NFTs.',
                        { variant: 'error' }
                      );
                    }
                    // else if(kycVerification == "Failed")
                    // {
                    //   return enqueueSnackbar(
                    //   "Your KYC status failed, hence unable to mint NFT's.",
                    //   { variant: "error" }
                    // )}
                    else if (props?.item?.tokenStandard === 'ERC-721') buyNow();
                    else purchase();
                  }}
                >
                  {btn_txt}
                  {isLoading && <Spin size="small" />}
                </button>
              )}
            </>
          )}
          {errormsg && <div className={styles.alert}>{errormsg}</div>}
          {isSuccess && (
            <div className={styles.success}>
              {' '}
              <div>You have bought the asset successfully.</div>
              {/* <div style={{color:'black'}}>View the purchased NFT in My Account.</div> */}
              {/* <div><a href="https://opensea.io/assets/0x474ABA21846bF2862946c63bDfF6e479D03455a2/1" target="_blank" > click here to view on opensea market </a></div> */}
            </div>
          )}
          {isSuccess && (
            <div
              style={{
                fontFamily: 'RedHatDisplay',
                fontSize: '15px',
                marginBottom: '16px',
                textAlign: 'center',
              }}
            >
              View the purchased NFT in{' '}
              <Link to={'/profile/' + account}>My Account.</Link>
            </div>
          )}
          {/* {isLoading && txHash === "" ? (
          <div
            className="success"
            style={{
              fontFamily: 'RedHatDisplay',
              fontSize: "13px",
              marginTop: "10px",
            }}
          >
            Minting please wait...
          </div>
        ) : null} */}
          {txHash && (
            <div
              className="success"
              style={{
                fontFamily: 'RedHatDisplay',
                fontSize: '13px',
                marginBottom: '16px',
                textAlign: 'center',
              }}
            >
              {isSuccess ? 'Transaction Completed.' : 'Transaction submitted.'}
              {''}{' '}
              <a
                href={transactionLink + txHash}
                target="_blank"
                rel="noreferrer"
                style={{ cursor: 'pointer' }}
              >
                View the transaction at polygonscan
              </a>
            </div>
          )}
        </div>
      )}

      <Modal visible={isSelectWallet} onClose={() => setIsSelectWallet(false)}>
        <div className={styles.modal}>
          <div className={cn('h4')}>Connect Wallet</div>
          <br />
          <div>
            Please connect your wallet to continue.
            <br /> The system supports the following wallets
            <br />
            <br />
          </div>
          <div
            className={styles.wallet}
            onClick={() => {
              if (isMobile && !window.ethereum) {
                window.location =
                  'https://metamask.app.link/dapp/nft.wowtalkies.com/collectionDetail/items/' +
                  props.item._id;
              } else {
                wallet();
              }
            }}
          >
            <img
              className={styles.metamask}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/800px-MetaMask_Fox.svg.png"
            />
            <div className={styles.btnContent}>
              <span className={styles.left1}>MetaMask</span>
            </div>
            <img
              className={styles.iconimg}
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjAyMTUgOC42NDVMMTUuMzY0OCAxNEwxMC4wMjE1IDE5LjM1NUwxMS42NjY1IDIxTDE4LjY2NjUgMTRMMTEuNjY2NSA3TDEwLjAyMTUgOC42NDVaIiBmaWxsPSIjNzc3RTkwIi8+Cjwvc3ZnPgo="
            />
          </div>
        </div>
      </Modal>
      <Modal visible={isDelevered} onClose={() => setIsDeleverd(false)}>
        <div>
          <div className={styles.delevery}>
            <img
              src="https://cdn.dribbble.com/users/379798/screenshots/3244368/scooter-running.gif"
              height="50px"
            />
            <div className={cn('h5')}>
              Do you want to get this product to be delivered at your address
            </div>
          </div>
          <br />
          <div className={styles.buttons}>
            <button
              className={cn('button', styles.button, styles.leftbtn)}
              onClick={() => {
                // if (address) purchase();
                // else setIsSelectWallet(true);
                setDeleverd(true);
              }}
            >
              Continue
            </button>
            <button
              className={cn('button-stroke', styles.button, styles.rightbtn)}
              onClick={() => {
                setIsDeleverd(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={delevered}
        onClose={() => setDeleverd(false)}
        outerClassName={styles.deleveryModal}
      >
        <div>
          <center className={cn('h4')}>Delivery address</center>
          <br />
          <Row>
            <Col span={11}>
              <TextInput1
                className={styles.field}
                label="Name"
                name="name"
                // value={title}
                type="text"
                // onChange={(e) => {
                //   titleFunction(e);
                // }}
                placeholder="Name"
                required
              />
              <TextArea
                className={styles.field}
                label="Address"
                name="address"
                // value={description}
                // onChange={(e) => {
                //   descriptionFunction(e);
                // }}
                type="text"
                placeholder="2/8, adayar"
                required
              />
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <TextInput1
                className={styles.field}
                label="City"
                name="city"
                // value={title}
                type="text"
                // onChange={(e) => {
                //   titleFunction(e);
                // }}
                placeholder="Chennai"
                required
              />
              <TextInput1
                className={styles.field}
                label="Country"
                name="country"
                // value={title}
                type="text"
                // onChange={(e) => {
                //   titleFunction(e);
                // }}
                placeholder="India"
                required
              />
              <TextInput1
                className={styles.field}
                label="Phone"
                name="phone"
                // value={title}
                type="text"
                // onChange={(e) => {
                //   titleFunction(e);
                // }}
                placeholder="9876543210"
                required
              />
              <div className={styles.buttons}>
                <button
                  className={cn('button', styles.button, styles.leftbtn)}
                  onClick={() => {
                    // if (address) purchase();
                    // else setIsSelectWallet(true);
                    // setIsDeleverd(true)
                  }}
                >
                  Continue
                </button>
                <button
                  className={cn(
                    'button-stroke',
                    styles.button,
                    styles.rightbtn
                  )}
                  onClick={() => {
                    setDeleverd(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default Purchase;
